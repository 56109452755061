import React, { useState, useEffect, useMemo, useCallback, Suspense, useRef } from "react";
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { debounce } from 'lodash';
import "./../style/vehicles.css";
import { Link } from "react-router-dom";
import { ReactComponent as LogoSvg } from "./../images/app-bar-logo-dark.svg";
import Sidebar from "./../components/sidebar";


const CarCard = ({ car }) => {
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };
  return (
    <Link to={`/vehicle/${car.id}`} className="link-new">
      <div className="car-card-new">
        <div
          className="car-image-new"
          style={{
            backgroundImage: imageError
              ? `url('/path-to-fallback-image.jpg')`
              : `url(${car.images[0] || '/path-to-fallback-image.jpg'})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
          onError={handleImageError}
        />
        <div className="car-header-new">
          <h3>{car.make} {car.model}</h3>
        </div>
        <div className="car-details-new">
          <table>
            <tbody>
              <tr>
                <td>Year:</td>
                <td>{car.year}</td>
              </tr>
              <tr>
                <td>Odometer:</td>
                <td>{car.odometer}km</td>
              </tr>
              <tr>
                <td>Engine Size:</td>
                <td>{car.engineSize}cc</td>
              </tr>
            </tbody>
          </table>
          <div className="car-price-new">
            <p>${car.price}</p>
          </div>
        </div>
      </div>
    </Link>
  );
};

const Vehicles = () => {
  const { type } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [carListings, setCarListings] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  const [loading, setLoading] = useState(true);
  const topRef = useRef(null);

  useEffect(() => {
    if (!loading && carListings && topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [loading, carListings]);

  // Fetch vehicles data from Firestore
  useEffect(() => {
    const fetchVehicles = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'vehicles'));
        const vehiclesList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          make: doc.data().make,
          model: doc.data().model,
          region: doc.data().region,
          year: doc.data().year,
          odometer: doc.data().odometer,
          engineSize: doc.data().engineSize,
          price: doc.data().price,
          images: doc.data().images,
        }));
        setCarListings(vehiclesList);
      } catch (error) {
        console.error('Error fetching vehicles:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchVehicles();
  }, []);

  // Sync search input with URL parameters
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const search = queryParams.get('search');
    setSearchQuery(search ? search.toLowerCase() : '');
    setSearchInput(search || '');
  }, [location.search]);

  // Debounced search input change handler
  const handleSearchChange = debounce((value) => {
    setSearchQuery(value.toLowerCase());
  }, 200);

  const onSearchInputChange = (e) => {
    const value = e.target.value;
    setSearchInput(value);
    handleSearchChange(value);
  };

  // Trigger search and update URL
  const handleSearch = useCallback(() => {
    if (searchInput.trim()) {
      navigate(`/vehicles?search=${encodeURIComponent(searchInput.trim())}`);
    } else {
      navigate(`/vehicles`);
    }
  }, [searchInput, navigate]);

  // Handle Enter key press for search
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  // Region filter click handler
  const handleRegionClick = useCallback((region) => {
    setSelectedRegion(region);
  }, []);

  // Memoized filtered car listings
  const filteredCars = useMemo(() => {
    return carListings.filter((car) => {
      const matchesType = !type || car.type === type;
      const matchesRegion = !selectedRegion || car.region.toLowerCase() === selectedRegion.toLowerCase();
      const matchesSearch = !searchQuery || car.make.toLowerCase().includes(searchQuery) || car.model.toLowerCase().includes(searchQuery);
      return matchesType && matchesRegion && matchesSearch;
    });
  }, [carListings, type, selectedRegion, searchQuery]);

  return (
    <>
      <div ref={topRef}>
        <Sidebar />
      </div>
      <HelmetProvider>
        <Helmet>
          <title>Vehicles For Sale | Swift Bros Auto</title>
          <meta name="description" content="Vehicles for sale" />
          <meta name="keywords" content="vehicles, cars, trucks, vans" />
          <link rel="canonical" href="https://www.swiftbrosauto.com/vehicles" />
        </Helmet>
      </HelmetProvider>
      <div className="vehicles-page">
        <div className="vehicles-container-new">
          <div className="search-section-new">
            <input
              type="text"
              className="search-bar"
              placeholder="QUICK SEARCH"
              value={searchInput}
              onChange={onSearchInputChange}
              onKeyDown={handleKeyDown}
            />
            <button onClick={handleSearch} className="search-button">Search</button>
          </div>
          <div className="region-buttons-new">
            <button
              className={`region-button-new ${selectedRegion === 'wellington' ? 'active' : ''}`}
              onClick={() => handleRegionClick('wellington')}
            >
              Wellington
            </button>
            <button
              className={`region-button-new ${selectedRegion === 'christchurch' ? 'active' : ''}`}
              onClick={() => handleRegionClick('christchurch')}
            >
              Christchurch
            </button>
          </div>
          {loading ? (
            <div className="skeleton-container">
              {Array.from({ length: 8 }).map((_, index) => (
                <div key={index} className="car-card-new skeleton-card">
                  <div className="car-image-new skeleton-image"></div>
                  <div className="car-header-new skeleton-text"></div>
                  <div className="car-details-new">
                    <div className="skeleton-text"></div>
                    <div className="skeleton-text"></div>
                    <div className="skeleton-text"></div>
                  </div>
                </div>
              ))}
            </div>
          ) : filteredCars.length === 0 ? (
            <h2 className="title-new centered-space-new">No search results found</h2>
          ) : (
            <Suspense fallback={<div>Loading...</div>}>
              <div className="car-listings-new">
                {filteredCars.map((car) => (
                  <CarCard key={car.id} car={car} />
                ))}
              </div>
            </Suspense>
          )}
        </div>
        <section className="text-section">
          <h3>A BETTER WAY OF BUYING.</h3>
          <section className="logo-banner-home">
            <LogoSvg className="logo-svg-home" />
          </section>
        </section>
      </div>
    </>
  );
};

export default React.memo(Vehicles);

