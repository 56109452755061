import React, { useState, useEffect } from 'react';
import { collection, getDocs, deleteDoc, doc, addDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../firebase';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box, Select, MenuItem, InputLabel, FormControl, CircularProgress } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import imageCompression from 'browser-image-compression';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './../style/admin.css';

const AdminDashboard = () => {
  const [submissions, setSubmissions] = useState([]);
  const [filteredSubmissions, setFilteredSubmissions] = useState([]);
  const [selectedSubmission, setSelectedSubmission] = useState(null);
  const [vehicles, setVehicles] = useState([]);
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [newVehicle, setNewVehicle] = useState({
    make: '',
    model: '',
    year: '',
    odometer: '',
    engineSize: '',
    price: '',
    images: [],
    region: '',
    type: '',
    description: ''
  });
  const [filter, setFilter] = useState('all');
  const [vehicleDialogOpen, setVehicleDialogOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editingVehicleId, setEditingVehicleId] = useState(null);
  const [uploadProgress, setUploadProgress] = useState({});
  const [imageUrls, setImageUrls] = useState([]);
  const [originalImageUrls, setOriginalImageUrls] = useState([]);

  useEffect(() => {
    const fetchSubmissions = async () => {
      const querySnapshot = await getDocs(collection(db, 'carSubmissions'));
      const submissionsList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setSubmissions(submissionsList);
      setFilteredSubmissions(submissionsList);
    };

    const fetchVehicles = async () => {
      const querySnapshot = await getDocs(collection(db, 'vehicles'));
      const vehiclesList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setVehicles(vehiclesList);
      setFilteredVehicles(vehiclesList);
    };

    fetchSubmissions();
    fetchVehicles();
  }, []);

  useEffect(() => {
    if (filter === 'all') {
      setFilteredSubmissions(submissions);
      setFilteredVehicles(vehicles);
    } else {
      const lowercaseFilter = filter.toLowerCase();
      setFilteredSubmissions(submissions.filter(submission => submission.branch.toLowerCase() === lowercaseFilter));
      setFilteredVehicles(vehicles.filter(vehicle => vehicle.region.toLowerCase() === lowercaseFilter));
    }
  }, [filter, submissions, vehicles]);

  const handleSubmissionClick = (submission) => {
    setSelectedSubmission(submission);
  };

  const handleClose = () => {
    setSelectedSubmission(null);
  };

  const handleFilterChange = (branch) => {
    setFilter(branch);
  };

  const handleDelete = async (id, collectionName) => {
    try {
      await deleteDoc(doc(db, collectionName, id));
      if (collectionName === 'carSubmissions') {
        setSubmissions(submissions.filter(submission => submission.id !== id));
        setFilteredSubmissions(filteredSubmissions.filter(submission => submission.id !== id));
      } else if (collectionName === 'vehicles') {
        setVehicles(vehicles.filter(vehicle => vehicle.id !== id));
        setFilteredVehicles(filteredVehicles.filter(vehicle => vehicle.id !== id));
      }
      handleClose();
    } catch (error) {
      console.error('Error deleting submission', error);
    }
  };

  const handleAddOrEditVehicle = async () => {
    try {
      const updatedVehicle = {
        ...newVehicle,
        description: newVehicle.description.replace(/\n/g, '<br>'),
        images: imageUrls.length > 0 ? imageUrls : originalImageUrls // Use originalImageUrls if no new images are uploaded
      };

      if (editMode) {
        const vehicleRef = doc(db, 'vehicles', editingVehicleId);
        await updateDoc(vehicleRef, updatedVehicle);
        setVehicles(vehicles.map(vehicle => vehicle.id === editingVehicleId ? { ...updatedVehicle, id: editingVehicleId } : vehicle));
        setFilteredVehicles(filteredVehicles.map(vehicle => vehicle.id === editingVehicleId ? { ...updatedVehicle, id: editingVehicleId } : vehicle));
      } else {
        const docRef = await addDoc(collection(db, 'vehicles'), updatedVehicle);
        setVehicles([...vehicles, { ...updatedVehicle, id: docRef.id }]);
        setFilteredVehicles([...filteredVehicles, { ...updatedVehicle, id: docRef.id }]);
      }

      handleCloseVehicleDialog();
    } catch (error) {
      console.error('Error adding or editing vehicle', error);
    }
  };

  const uploadImage = async (image) => {
    const compressedImage = await imageCompression(image, {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    });

    const imageRef = ref(storage, `vehicles/${image.name}`);
    const uploadTask = uploadBytesResumable(imageRef, compressedImage);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setTimeout(() => {
            setUploadProgress((prev) => ({
              ...prev,
              [image.name]: progress
            }));
          }, 300);
        },
        (error) => {
          console.error('Upload failed:', error);
          reject(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          setImageUrls((prev) => [...prev, downloadURL]);
          resolve(downloadURL);
        }
      );
    });
  };

  const handleEdit = (vehicle) => {
    setNewVehicle(vehicle);
    setEditingVehicleId(vehicle.id);
    setEditMode(true);
    setVehicleDialogOpen(true);
    setImageUrls([]); // Clear any previously uploaded images
    setOriginalImageUrls(vehicle.images); // Store the original images to be used if no changes are made
  };

  const handleImageChange = async (e) => {
    const selectedFiles = Array.from(e.target.files);
  
    // Set local images immediately for preview before they are fully uploaded
    const localImages = selectedFiles.map(file => URL.createObjectURL(file));
    setNewVehicle(prevVehicle => ({
      ...prevVehicle,
      images: [...prevVehicle.images, ...localImages]
    }));
  
    // Upload the images and update the state with their URLs
    const uploadPromises = selectedFiles.map(file => uploadImage(file));
    const uploadedUrls = await Promise.all(uploadPromises);
  
    // Replace the local images with the uploaded URLs
    setNewVehicle(prevVehicle => ({
      ...prevVehicle,
      images: prevVehicle.images.map(image => {
        return localImages.includes(image) ? uploadedUrls[localImages.indexOf(image)] : image;
      })
    }));
  };

  const handleCloseVehicleDialog = () => {
    setNewVehicle({
      make: '',
      model: '',
      year: '',
      odometer: '',
      engineSize: '',
      price: '',
      images: [],
      region: '',
      type: '',
      description: ''
    });
    setVehicleDialogOpen(false);
    setEditMode(false);
    setEditingVehicleId(null);
    setUploadProgress({});
    setImageUrls([]);
    setOriginalImageUrls([]);
  };

  return (
    <div className="dashboard-container">
      <div className="filter-buttons">
        <button onClick={() => handleFilterChange('all')} className={filter === 'all' ? 'active' : ''}>All</button>
        <button onClick={() => handleFilterChange('christchurch')} className={filter === 'christchurch' ? 'active' : ''}>Christchurch</button>
        <button onClick={() => handleFilterChange('wellington')} className={filter === 'wellington' ? 'active' : ''}>Wellington</button>
      </div>

      <h2>Submissions</h2>
      <table className="dashboard-table">
        <thead>
          <tr>
            <th>Plate</th>
            <th>Phone Number</th>
            <th>Branch</th>
          </tr>
        </thead>
        <tbody>
          {filteredSubmissions.map(submission => (
            <tr key={submission.id} onClick={() => handleSubmissionClick(submission)}>
              <td>{submission.regoNumber}</td>
              <td>{submission.phone}</td>
              <td>{submission.branch}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {selectedSubmission && (
        <Dialog open={true} onClose={handleClose}>
          <DialogTitle>Submission Details</DialogTitle>
          <DialogContent className="dialog-content">
            <p><strong>Make:</strong> {selectedSubmission.make}</p>
            <p><strong>Model:</strong> {selectedSubmission.model}</p>
            <p><strong>Registration Number:</strong> {selectedSubmission.regoNumber}</p>
            <p><strong>Odometer:</strong> {selectedSubmission.odometer}</p>
            <p><strong>Year:</strong> {selectedSubmission.year}</p>
            <p><strong>Modified:</strong> {selectedSubmission.modified ? 'Yes' : 'No'}</p>
            {selectedSubmission.modified && (
              <p><strong>Modified Details:</strong> {selectedSubmission.modifiedDetails}</p>
            )}
            <p><strong>Mechanical Issues:</strong> {selectedSubmission.mechanicalIssues ? 'Yes' : 'No'}</p>
            {selectedSubmission.mechanicalIssues && (
              <p><strong>Mechanical Details:</strong> {selectedSubmission.mechanicalDetails}</p>
            )}
            <p><strong>Cosmetic Issues:</strong> {selectedSubmission.cosmeticIssues ? 'Yes' : 'No'}</p>
            {selectedSubmission.cosmeticIssues && (
              <p><strong>Cosmetic Details:</strong> {selectedSubmission.cosmeticDetails}</p>
            )}
            <p><strong>Price:</strong> {selectedSubmission.price}</p>
            <p><strong>First Name:</strong> {selectedSubmission.firstName}</p>
            <p><strong>Last Name:</strong> {selectedSubmission.lastName}</p>
            <p><strong>Phone:</strong> {selectedSubmission.phone}</p>
            <p><strong>Email:</strong> {selectedSubmission.email}</p>
            <p><strong>Preferred Contact:</strong> {selectedSubmission.preferredContact}</p>
            <p><strong>Branch:</strong> {selectedSubmission.branch}</p>
            <p><strong>Price Negotiable:</strong> {selectedSubmission.priceNegotiable ? 'Yes' : 'No'}</p>
            <div>
              <strong>Photos:</strong>
              <Swiper
                spaceBetween={10}
                slidesPerView={1}
                pagination={{ clickable: true }}
                navigation={true}
                modules={[Pagination, Navigation]}
              >
                {selectedSubmission.photos.map((url, index) => (
                  <SwiperSlide key={index}>
                    <img src={url} alt={`Submission ${index + 1}`} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleDelete(selectedSubmission.id, 'carSubmissions')} color="error">Delete</Button>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      )}

      <h2>Vehicles</h2>
      <table className="dashboard-table">
        <thead>
          <tr>
            <th>Make</th>
            <th>Model</th>
            <th>Year</th>
            <th>Price</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredVehicles.map(vehicle => (
            <tr key={vehicle.id}>
              <td>{vehicle.make}</td>
              <td>{vehicle.model}</td>
              <td>{vehicle.year}</td>
              <td>{vehicle.price}</td>
              <td>
                <Button onClick={() => handleEdit(vehicle)} color="primary">Edit</Button>
                <Button onClick={() => handleDelete(vehicle.id, 'vehicles')} color="error">Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Button onClick={() => { setEditMode(false); setVehicleDialogOpen(true); }}>Add Vehicle</Button>

      <Dialog open={vehicleDialogOpen} onClose={handleCloseVehicleDialog}>
        <DialogTitle>{editMode ? "Edit Vehicle" : "Add Vehicle"}</DialogTitle>
        <DialogContent className="dialog-content">
          <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              label="Make"
              value={newVehicle.make}
              onChange={(e) => setNewVehicle({ ...newVehicle, make: e.target.value })}
              fullWidth
            />
            <TextField
              label="Model"
              value={newVehicle.model}
              onChange={(e) => setNewVehicle({ ...newVehicle, model: e.target.value })}
              fullWidth
            />
            <TextField
              label="Year"
              value={newVehicle.year}
              onChange={(e) => setNewVehicle({ ...newVehicle, year: e.target.value })}
              fullWidth
            />
            <TextField
              label="Odometer"
              value={newVehicle.odometer}
              onChange={(e) => setNewVehicle({ ...newVehicle, odometer: e.target.value })}
              fullWidth
            />
            <TextField
              label="Engine Size"
              value={newVehicle.engineSize}
              onChange={(e) => setNewVehicle({ ...newVehicle, engineSize: e.target.value })}
              fullWidth
            />
            <TextField
              label="Price"
              value={newVehicle.price}
              onChange={(e) => setNewVehicle({ ...newVehicle, price: e.target.value })}
              fullWidth
            />
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="upload-photo"
              type="file"
              multiple
              onChange={handleImageChange}
            />
            <label htmlFor="upload-photo">
              <Button variant="contained" component="span" startIcon={<PhotoCamera />}>
                Upload Photos
              </Button>
            </label>
            {newVehicle.images.length > 0 && (
              <div className="image-preview">
                <Swiper
                  spaceBetween={10}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  navigation={true}
                  modules={[Pagination, Navigation]}
                >
                  {newVehicle.images.map((image, index) => (
                    <SwiperSlide key={index}>
                      {typeof image === 'string' ? (
                        <img src={image} alt={`Vehicle Photo ${index + 1}`} className="image-preview" />
                      ) : (
                        <>
                          <p>{image.name}</p>
                          <CircularProgressWithLabel value={uploadProgress[image.name] || 0} />
                        </>
                      )}
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            )}
            <FormControl fullWidth>
              <InputLabel id="region-label">Region</InputLabel>
              <Select
                labelId="region-label"
                value={newVehicle.region}
                onChange={(e) => setNewVehicle({ ...newVehicle, region: e.target.value })}
              >
                <MenuItem value="Christchurch">Christchurch</MenuItem>
                <MenuItem value="Wellington">Wellington</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="type-label">Type</InputLabel>
              <Select
                labelId="type-label"
                value={newVehicle.type}
                onChange={(e) => setNewVehicle({ ...newVehicle, type: e.target.value })}
              >
                <MenuItem value="Sedan">Sedan</MenuItem>
                <MenuItem value="SUV">SUV</MenuItem>
                <MenuItem value="Truck">Truck</MenuItem>
                <MenuItem value="Coupe">Coupe</MenuItem>
                <MenuItem value="Hatchback">Hatchback</MenuItem>
                <MenuItem value="Convertible">Convertible</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Description"
              value={newVehicle.description}
              onChange={(e) => setNewVehicle({ ...newVehicle, description: e.target.value })}
              fullWidth
              multiline
              rows={4}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddOrEditVehicle}>{editMode ? "Save Changes" : "Add"}</Button>
          <Button className='cancel-button' onClick={handleCloseVehicleDialog}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const CircularProgressWithLabel = ({ value }) => {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" value={value} />
      {value === 100 ? (
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CheckCircleIcon color="success" />
        </Box>
      ) : (
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box>{`${Math.round(value)}%`}</Box>
        </Box>
      )}
    </Box>
  );
};

export default AdminDashboard;
