import React from 'react';
import facebookLogo from "./../images/facebook.webp";
import instagramLogo from "./../images/instagram.webp";
import "./../style/footer.css";

const Footer = () => {
    return (
        <footer className="footer" id="footer">
        <h2>Get in Contact</h2>
        <div className="contact-row">
            <div className="contact-column">
            <h3>Canterbury</h3>
            <p>020 4119 5929</p>
            <p>SwiftBrosAuto.CA@gmail.com</p>
            </div>
            <div className="contact-column reverse">
            <h3>Wellington</h3>
            <p>021 114 3487</p>
            <p>SwiftBrosAutomotive@gmail.com</p>
            </div>
        </div>
        <div className="social-container">
            <div className="social-icons">
            <a
                href="https://www.facebook.com/swifts.automotives"
                aria-label="Swifts Automotives"
                target="_blank"
                rel="noopener noreferrer"
                loading="lazy"
            >
                <img src={facebookLogo} alt="Facebook" width="600" height="400" />
            </a>
            <a
                href="https://www.instagram.com/swift_bros_automotive"
                aria-label="Swift Bros Automotive"
                target="_blank"
                rel="noopener noreferrer"
                loading="lazy"
            >
                <img src={instagramLogo} alt="Instagram" width="600" height="400" />
            </a>
            </div>
        </div>
        </footer>
    );
};

export default Footer;
